
import { defineComponent } from "vue";
import Dropdown1 from "@/components/dropdown/Dropdown1.vue";

export default defineComponent({
  name: "kt-widget-1",
  components: {
    Dropdown1
  },
  props: {
    widgetClasses: String
  },
  setup() {
    const items = [
      {
        icon: "media/icons/duotone/Home/Library.svg",
        color: "success",
        title: "Project Briefing",
        description: "Project Manager"
      },
      {
        icon: "media/icons/duotone/Communication/Write.svg",
        color: "warning",
        title: "Concept Design",
        description: "Art Director"
      },
      {
        icon: "media/icons/duotone/Communication/Group-chat.svg",
        color: "blue",
        title: "Functional Logics",
        description: "Lead Developer"
      },
      {
        icon: "media/icons/duotone/General/Attachment2.svg",
        color: "danger",
        title: "Development",
        description: "DevOps"
      },
      {
        icon: "media/icons/duotone/Communication/Shield-user.svg",
        color: "info",
        title: "Testing",
        description: "QA Managers"
      }
    ];

    return {
      items
    };
  }
});
